import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Card as RebassCard } from 'rebass'
import BasketIcon from '../../assets/svg-icons/basket.svg'

const Card = styled(RebassCard)`
  background-color: ${props => props.bg};
  background-clip: border-box;
  box-shadow: 0 10px 40px 0 rgba(18, 106, 211, 0.07),
    0 2px 9px 0 rgba(18, 106, 211, 0.06);
  border-radius: 0.25rem;
  border: none;
  margin-bottom: 1rem;
  transition: all 0.3s ease;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  padding: 0;
`
const CardBody = styled.div`
  flex: 1 1 auto;
`
const CardTitle = styled.div`
  color: white;
  font-size: 1rem;
  padding: 10px 0 0 20px;
  font-family: Poppins, sans-serif;
  font-weight: 100;
  letter-spacing: 1px;
`
const CardContent = styled.div`
  color: white;
  font-size: 2.5rem;
  padding-left: 20px;
  padding-bottom: 10px;
  font-family: Poppins, sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const CardChart = styled.div``

const Icon = styled.img`
  max-width: 30px;
  opacity: 0.2;
  margin-right: 20px;
`

class CardBox extends Component {
  render() {
    return (
      <Card bg={this.props.bg}>
        <CardBody>
          <CardTitle> {this.props.text}</CardTitle>
          <CardContent>
            <span>3,233</span>
            <Icon src={this.props.icon} />
          </CardContent>
        </CardBody>
      </Card>
    )
  }
}

CardBox.defaultProps = {
  bg: '#635ebe',
  text: 'Stores',
  icon: BasketIcon,
}

CardBox.propTypes = {
  bg: PropTypes.any,
  icon: PropTypes.any,
  text: PropTypes.any,
}

export default CardBox

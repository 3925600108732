import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ChartistGraph from 'react-chartist'
import styled from 'styled-components'
import './style.scss'

const CardWrapper = styled.div`
  width: 100%;
  max-width: 980px;
  margin: 0 auto;
  box-shadow: 0 10px 40px 0 rgba(18, 106, 211, 0.07),
    0 2px 9px 0 rgba(18, 106, 211, 0.06);
  border-radius: 0.25rem;
  padding-top: 10px;
  background: white;
`

const data = {
  labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
  series: [
    [12, 9, 7, 8, 5],
    [2, 1, 3.5, 7, 3],
    [1, 3, 4, 5, 6],
    [4, 3, 8, 5, 3],
  ],
}

const options = {
  fullWidth: true,
  chartPadding: {
    right: 40,
  },
  height: '295px',
}

const type = 'Line'

class SimpleChart extends Component {
  render() {
    return (
      <CardWrapper>
        <ChartistGraph data={data} options={options} type={type} />
      </CardWrapper>
    )
  }
}

SimpleChart.propTypes = {}

export default SimpleChart

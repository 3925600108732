import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Card as RebassCard, Image } from 'rebass'
import SignalIcon from '../../assets/signal-bars.svg'

const Card = styled(RebassCard)`
  background-color: ${props => props.bg};
  background-clip: border-box;
  box-shadow: 0 10px 40px 0 rgba(18, 106, 211, 0.07),
    0 2px 9px 0 rgba(18, 106, 211, 0.06);
  border-radius: 0.25rem;
  border: none;
  margin-bottom: 1.875rem;
  transition: all 0.3s ease;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  padding: 0;
  height: 100%;
  justify-content: center;
  align-items: center;
`
const CardBody = styled.div`
  background-color: ${props => props.bg};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
`
const CardTitle = styled.div`
  color: white;
  font-size: 1rem;
  padding: 20px 0 0 20px;
  font-family: Poppins, sans-serif;
  font-weight: 100;
  letter-spacing: 1px;
`
const CardContent = styled.div`
  color: white;
  font-size: 2.7rem;
  font-family: Poppins, sans-serif;
`

class BigCardBox extends Component {
  render() {
    return (
      <Card bg={this.props.bg}>
        <CardBody bg="#2ECC71">
          <CardContent>
            <Image src={SignalIcon} width={70} />{' '}
          </CardContent>
        </CardBody>
        <CardBody bg="#3EDC81">
          <CardContent>+ 13,233 </CardContent>
          <CardTitle> Increase sales</CardTitle>
        </CardBody>
      </Card>
    )
  }
}

BigCardBox.defaultProps = {
  bg: '#635ebe',
}

BigCardBox.propTypes = {
  bg: PropTypes.any,
  icon: PropTypes.any,
}

export default BigCardBox

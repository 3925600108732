import { Box, Flex } from 'rebass'
import CardBox from './DashBoxes/CardBox'
import BasketIcon from '../assets/svg-icons/home.svg'
import NotificationIcon from '../assets/svg-icons/bell-black.svg'
import TasksIcon from '../assets/svg-icons/list.svg'
import TotalScoreIcon from '../assets/svg-icons/pulse.svg'
import SimpleChart from './Chart/SimpleChart'
import BigCardBox from './DashBoxes/BigCardBox'
import SimpleTable from './Table/SimpleTable'
import SimpleMap from './Elements/SimpleMap'
import Layout from './layout'
import React from 'react'
import FullScreenLoader from './FullScreenLoader'
import { connect } from 'redux-zero/react'
import actions from '../store/actions'

class MainPage extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Layout>
        <Flex className="boxes-row" flexWrap="wrap" mb={3}>
          <Box width={[1, 1 / 2, 1 / 4]} px={2}>
            <CardBox bg="#AB69C6" text="Stores" icon={BasketIcon} />
          </Box>
          <Box width={[1, 1 / 2, 1 / 4]} px={2}>
            <CardBox
                bg="#F75C4C"
                text="Notifications"
                icon={NotificationIcon}
            />
          </Box>
          <Box width={[1, 1 / 2, 1 / 4]} px={2}>
            <CardBox bg="#F5D76E" text="Tasks" icon={TasksIcon} />
          </Box>
          <Box width={[1, 1 / 2, 1 / 4]} px={2}>
            <CardBox bg="#22A7F0" text="Total Score" icon={TotalScoreIcon} />
          </Box>
        </Flex>

        <Flex className="boxes-row" flexWrap="wrap" mb={4}>
          <Box width={[1, 1, '75%']} px={2}>
            <SimpleChart />
          </Box>
          <Box width={[1, 1, '25%']} px={2}>
            <BigCardBox />
          </Box>
        </Flex>

        <Flex className="boxes-row" flexWrap="wrap">
          <Box width={[1, 1, 1, 1 / 2]} px={2}>
            <SimpleTable />
          </Box>
          <Box width={[1, 1, 1, 1 / 2]} px={2}>
            <SimpleMap />
          </Box>
        </Flex>
        <FullScreenLoader loading={this.props.loading} />
      </Layout>
    )
  }
}

const mapStateToProps = ({ loading }) => ({
  loading,
})

export default connect(
  mapStateToProps,
  actions
)(MainPage)

import React from 'react'
import { connect } from 'redux-zero/react'
import actions from '../store/actions'
import Login from '../components/Login'
import MainPage from '../components/MainPage'

class IndexPage extends React.Component {
  componentDidMount() {
    this.props.checkToken()
    console.log('start website')
  }

  page_logic = () => {
    if (this.props.auth === true) {
      return <MainPage />
    } else {
      return <Login />
    }
  }

  render() {
    return <div> {this.page_logic()}</div>
  }
}

const mapStateToProps = ({ auth, loading }) => ({
  auth,
  loading,
})

export default connect(
  mapStateToProps,
  actions
)(IndexPage)

import React, { Component } from 'react'
import styled from 'styled-components'

import './style.css'

const TableWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 980px;
  margin: 0 auto;
  box-shadow: 0 10px 40px 0 rgba(18, 106, 211, 0.07),
    0 2px 9px 0 rgba(18, 106, 211, 0.06);
  border-radius: 0.25rem;
  background: white;
  overflow: hidden;
`

class SimpleTable extends Component {
  render() {
    return (
      <TableWrapper>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Store</th>
                <th>Notifications</th>
                <th>Tasks</th>
                <th>Rank</th>
                <th>Sales</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>China</td>
                <td>1,415,045,928</td>
                <td>0.39 %</td>
                <td>5,528,531</td>
                <td>0.39 %</td>
              </tr>
              <tr>
                <td>India</td>
                <td>1,354,051,854</td>
                <td>1.11 %</td>
                <td>14,871,727</td>
                <td>0.39 %</td>
              </tr>
              <tr>
                <td>U.S.</td>
                <td>326,766,748</td>
                <td>0.71 %</td>
                <td>2,307,285</td>
                <td>0.39 %</td>
              </tr>
              <tr>
                <td>Indonesia</td>
                <td>266,794,980</td>
                <td>1.06 %</td>
                <td>2,803,601</td>
                <td>0.39 %</td>
              </tr>
              <tr>
                <td>Brazil</td>
                <td>210,867,954</td>
                <td>0.75 %</td>
                <td>1,579,676</td>
                <td>0.39 %</td>
              </tr>
            </tbody>
          </table>
        </div>
      </TableWrapper>
    )
  }
}

SimpleTable.propTypes = {}

export default SimpleTable
